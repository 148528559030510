import React from 'react';

const Header = () => {
  return (
    <header className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6 text-center overflow-hidden relative">
        <div className="animate-circle whitespace-nowrap">
          <h1 className="text-2xl font-bold inline-block">
            Animal Movie Download | Animal Full Movie | Animal Full Movie Download | Animal Movie Bollywood
          </h1>
        </div>
        <div className="mt-2">
          <a
            href="https://snapmoviehd.com/download/animal-movie-download"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-600"
          >
            Animal Full Movie Download Link
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
